/*
 *  Teachably also relies on sanitize.css as a reset.
 *  Has box-sizing as default. See docs for more info.
 *  https://github.com/jonathantneal/sanitize.css
*/

@font-face {
  font-family: 'RealHead';
  src: url('../assets/fonts/Real/RealHeadWeb-Light.woff') format('woff');
  font-weight: 300;
}
@font-face {
  font-family: 'RealHead';
  src: url('../assets/fonts/Real/RealHeadWeb.woff') format('woff');
  font-weight: 400;
}
@font-face {
  font-family: 'RealHead';
  src: url('../assets/fonts/Real/RealHeadWeb-Medium.woff') format('woff');
  font-weight: 500;
}
@font-face {
  font-family: 'RealHead';
  src: url('../assets/fonts/Real/RealHeadWeb-Bold.woff') format('woff');
  font-weight: 700;
}
@font-face {
  font-family: 'RealHead';
  src: url('../assets/fonts/Real/RealHeadWeb-Black.woff') format('woff');
  font-weight: 900;
}

@font-face {
  font-family: 'RealText';
  src: url('../assets/fonts/Real/RealTextWeb-Bold.woff') format('woff');
  font-weight: 700;
}
@font-face {
  font-family: 'RealText';
  src: url('../assets/fonts/Real/RealTextWeb-Medium.woff') format('woff');
  font-weight: 500;
}
@font-face {
  font-family: 'RealText';
  src: url('../assets/fonts/Real/RealTextWeb.woff') format('woff');
  font-weight: 400;
}
@font-face {
  font-family: 'RealText';
  src: url('../assets/fonts/Real/RealTextWeb-Light.woff') format('woff');
  font-weight: 300;
}

/* Font-family falls back to native font stack
 * https://www.smashingmagazine.com/2015/11/using-system-ui-fonts-practical-guide/
*/
html,
body {
  width: 100%;
  height: 100%;
  font-family: 'RealText', -apple-system, system-ui, BlinkMacSystemFont,
    'Segoe UI', 'Helvetica Neue', Arial, sans-serif;
  font-weight: 400;
  color: #343a40;
}

#root {
  /* need 100% height on the root div so that backgrounds can fill the screen */
  height: 100%;
}

/* set placeholder globally to sync with draft-js editor */
/* maybe one day figure out doing it in MUI without !importants */
input::placeholder {
  color: #868e96 !important;
  opacity: 1 !important;
  font-size: inherit !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  font-family: RealHead, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI,
    Roboto, Helvetica Neue, Arial, sans-serif !important;
}

/* Firebase Auth Styling Overrides */
.mdl-card {
  background-color: rgba(0, 0, 0, 0) !important;
  box-shadow: none !important;
}

.firebaseui-id-submit {
  background: #3164a3 !important;
}
